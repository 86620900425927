// main.js

// CLASS HELPERS
// Vanilla JS class toggling scripts for use without jQuery

// hasClass
function hasClass(elem, className) {
    return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
}

// addClass
function addClass(elem, className) {
    if (!hasClass(elem, className)) {
        elem.className += ' ' + className;
    }
}

// removeClass
function removeClass(elem, className) {
    var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';
    if (hasClass(elem, className)) {
        while (newClass.indexOf(' ' + className + ' ') >= 0) {
            newClass = newClass.replace(' ' + className + ' ', ' ');
        }
        elem.className = newClass.replace(/^\s+|\s+$/g, '');
    }
}

// toggleClass
function toggleClass(elem, className) {
    var newClass = ' ' + elem.className.replace(/[\t\r\n]/g, " ") + ' ';
    if (hasClass(elem, className)) {
        while (newClass.indexOf(" " + className + " ") >= 0) {
            newClass = newClass.replace(" " + className + " ", " ");
        }
        elem.className = newClass.replace(/^\s+|\s+$/g, '');
    } else {
        elem.className += ' ' + className;
    }
}



// MOBILE MENU TOGGLE
// toggle the mobile menu button open and closed states
var removeClass = true;
$(".navbar-toggler").click(function () {
  $(".navbar-toggler").toggleClass('is-active');
  removeClass = false;
});

// ignore clicking the navbar
$(".navbar").click(function() {
  removeClass = false;
});

// close nav if page is clicked
$("html").click(function () {
  if (removeClass) {
    $(".navbar-toggler").removeClass('is-active');
    $('.navbar-collapse').collapse('hide');  
  }
  removeClass = true;
});

// close nav if link is clicked
$(".navbar a").click(function () {
  if (removeClass) {
    $(".navbar-toggler").removeClass('is-active');
    $('.navbar-collapse').collapse('hide');  
  }
  removeClass = true;
});

// disable mobile nav for laptop and desktop
$(window).resize(function() {
  if( $(this).width() > 767 ) {
    $(".navbar-toggler").removeClass('is-active');
    $('.navbar-collapse').collapse('hide');  
  }
});




// CONTACT FORM
// If contact form is present, add action attribute and email
// Shortened from => if (typeof(contactForm) != 'undefined' && contactForm != null) 
var contactForm =  document.getElementById('contactform');
if ( contactForm != null ) {
    contactForm.setAttribute('action', '//formspree.io/' + 'mike' + '@' + 'losaidos' + '.' + 'com');
}

// SCROLLUP BUTTON
// show scrollup button after scrolling 300px
scrollToTopButton = document.getElementById("scrollUpButton");
var showScrollToTop = function () {
  var y = window.scrollY;
  if (y >= 300) {
    scrollToTopButton.className = "scrollup show"
  } else {
    scrollToTopButton.className = "scrollup"
  }
};
window.addEventListener("scroll", showScrollToTop);



// JQUERY USE STRICT SCRIPT WRAPPER
// scroll easing, scrollspy, fancybox, and isotope - comment what isn't needed, or add more
(function($) {
  "use strict"; // Start of use strict

  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html, body').animate({
          // scrollTop: (target.offset().top - 54)
          scrollTop: (target.offset().top - 0)
        }, 1000, "easeInOutExpo");
        return false;
      }
    }
  });


  
})(jQuery); // End of use strict



// COPYRIGHT YEAR
// Get current year for copyright in footer
document.getElementById("year").innerHTML = new Date().getFullYear();




// LIST.JS
// keg list sorting
var options = {
  valueNames: [ 'brand', 'size', 'cost' ]
};
var kegList = new List('kegList', options);

// faq sorting
var options = {
    valueNames: [ 'faq-question', 'faq-answer' ]
};
var faqList = new List('faq-list', options);



























// schedule for hours 
$(document).ready(function () {
    var $openOrClosed = $('#storeOpen');
    var $storeHours = $('#hours');

    // Only proceed if the element with ID "storeOpen" exists
    if ($openOrClosed.length) {
        // console.log('storeOpen element found, running the script.');

        // 0=sun, 1=mon, 2=tue, 3=wed, 4=thu, 5=fri, 6=sat
        var currentWeekday = new Date().getDay();
        var timeOfDay = new Date().getHours();

        // Define store hours
        const openingHour = 10; // 10 AM
        const closingHour = 18; // 6 PM

        // Check if it's Sunday (0) or Monday (1)
        if (currentWeekday === 0 || currentWeekday === 1) {
            $openOrClosed.html("We're out for the weekend, see you Tuesday!");
            // console.log('Sunday or Monday: Weekend message displayed.');
        } else {
            // For Tuesday (2) to Saturday (6)
            if (timeOfDay < openingHour) {
                $openOrClosed.html("We open at 10, come see us!");
                // console.log('Before opening hours.');
            } else if (timeOfDay >= openingHour && timeOfDay < closingHour) {
                $openOrClosed.html("We're open!");
                // console.log('Shop is open!');
            } else {
                $openOrClosed.html("Sorry we missed you, come back tomorrow!");
                // console.log('After closing hours.');
            }
        }

        // Add class if closed
        if (!$openOrClosed.html().includes("We're open!")) {
            $openOrClosed.addClass("closed");
            // if ($storeHours.length) {
            //     $storeHours.addClass("mb-0");
            // }
            // console.log('Shop is closed.');
        }
    } else {
        // console.log('storeOpen element not found.');
    }
});





// youtube api 
$(document).ready(function() {
  const apiKey = 'AIzaSyBjDhmM7khmLZaPQr_3VtVmXTi1WAJXyZo'; // Your YouTube API key
  const username = 'bangorwine'; // Bangor Wine YouTube channel username

  // Check if the necessary divs are present
  if ($('#youtube-video').length && $('#next-videos-section').length) {
    async function getVideos() {
      try {
        const channelResponse = await fetch(`https://www.googleapis.com/youtube/v3/channels?part=contentDetails&forUsername=${username}&key=${apiKey}`);
        const channelData = await channelResponse.json();
        
        if (!channelData.items || channelData.items.length === 0) {
          console.error('No channel data found.');
          return;
        }

        const uploadsPlaylistId = channelData.items[0].contentDetails.relatedPlaylists.uploads;

        // Fetch the latest video from the uploads playlist
        const playlistResponse = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${uploadsPlaylistId}&maxResults=1&key=${apiKey}`);
        const playlistData = await playlistResponse.json();

        if (!playlistData.items || playlistData.items.length === 0) {
          console.error('No videos found in the playlist.');
          return;
        }

        const latestVideo = playlistData.items[0];
        const latestVideoId = latestVideo.snippet.resourceId.videoId;
        const videoTitle = latestVideo.snippet.title;
        const videoDescription = latestVideo.snippet.description;
        const videoPublishedAt = latestVideo.snippet.publishedAt;

        // Extract category and clean title
        const [category, cleanedTitle] = extractCategoryAndTitle(videoTitle);

        // Embed the latest video into the div
        const videoDiv = $('#youtube-video');
        videoDiv.html(`
          <iframe width="560" height="315" src="https://www.youtube.com/embed/${latestVideoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `);

        // Fetch additional video details to get duration
        const videoDetailsResponse = await fetch(`https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${latestVideoId}&key=${apiKey}`);
        const videoDetailsData = await videoDetailsResponse.json();

        if (videoDetailsData.items && videoDetailsData.items.length > 0) {
          const videoDuration = videoDetailsData.items[0].contentDetails.duration;
          const formattedDuration = formatDuration(videoDuration);
          const formattedDate = formatDate(videoPublishedAt);

          $('#video-category').text(category);
          $('#video-title').text(cleanedTitle);
          $('#video-published-at').text(formattedDate);
          $('#video-duration').html(` &bull; ${formattedDuration}`);
          $('#video-description').text(videoDescription);
        }

        // Fetch next three videos, skipping the latest one
        const nextVideosResponse = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${uploadsPlaylistId}&maxResults=4&key=${apiKey}`);
        const nextVideosData = await nextVideosResponse.json();
        const nextVideosSection = $('#next-videos-section');
        nextVideosSection.empty(); // Clear any existing content

        if (nextVideosData.items && nextVideosData.items.length > 0) {
          // Loop through videos, starting from the second one to skip the latest
          nextVideosData.items.slice(1).forEach(item => {
            const videoId = item.snippet.resourceId.videoId;
            const title = item.snippet.title;
            const description = item.snippet.description;
            const publishedAt = item.snippet.publishedAt;

            // Extract category and clean title
            const [nextCategory, nextCleanedTitle] = extractCategoryAndTitle(title);

            // Fetch video details for duration
            fetch(`https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${videoId}&key=${apiKey}`)
              .then(response => response.json())
              .then(nextVideoDetailsData => {
                let formattedDuration = '';
                if (nextVideoDetailsData.items && nextVideoDetailsData.items.length > 0) {
                  const nextVideoDuration = nextVideoDetailsData.items[0].contentDetails.duration;
                  formattedDuration = formatDuration(nextVideoDuration);
                }

                // Create markup for the next video
                nextVideosSection.append(`
                  <div class="col-md-6 col-lg-4">
                    <div class="preview-item-container">
                      <div class="video-container">
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                      <div class="preview-text">
                        <p class="accent-text">${nextCategory}</p>
                        <h4>${nextCleanedTitle}</h4>
                        <p class="list-post-meta">
                          <span class="article-date" datetime="" itemprop="datePublished">
                            ${formatDate(publishedAt)} &bull; ${formattedDuration}
                          </span>
                        </p>
                        <p>${description}</p>
                        <a href="https://www.youtube.com/watch?v=${videoId}" class="read-more-link" target="_blank">View on YouTube</a>
                      </div>
                    </div>
                  </div>
                `);
              })
              .catch(error => console.error('Error fetching next video details:', error));
          });
        }
      } catch (error) {
        console.error('Error fetching the latest video:', error);
      }
    }

    // Function to extract category and clean title from the video title
    function extractCategoryAndTitle(title) {
      const parts = title.split(':');
      const category = parts[0].trim(); // Get the part before the colon
      const titleAfterColon = parts.length > 1 ? parts[1].trim() : '';
      const titleSplit = titleAfterColon.split('-');
      let cleanedTitle = titleAfterColon; // Default to the full title after the colon
      if (titleSplit.length > 1) {
        cleanedTitle = titleSplit.slice(1).join('-').trim(); // Join back remaining parts
      }
      return [category, cleanedTitle];
    }

    // Function to format the video duration
    function formatDuration(duration) {
      const matches = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
      const hours = matches[1] ? parseInt(matches[1].replace('H', '')) : 0;
      const minutes = matches[2] ? parseInt(matches[2].replace('M', '')) : 0;
      const seconds = matches[3] ? parseInt(matches[3].replace('S', '')) : 0;

      const formattedParts = [];
      if (hours > 0) {
        formattedParts.push(`${hours}hr`);
      }
      if (minutes > 0) {
        formattedParts.push(`${minutes}min`);
      }
      if (seconds > 0) {
        formattedParts.push(`${seconds}sec`);
      }
      return formattedParts.length > 0 ? formattedParts.join(' ') : '0sec';
    }

    // Function to format the date
    function formatDate(dateString) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      };
      return new Date(dateString).toLocaleDateString('en-US', options);
    }

    // Call the function to get and embed the latest video
    getVideos();
  }
});



// Make sure scripts rendered
$(document).ready(function (){
  console.log('document is ready');
});







